import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { layoutStyles } from '../style/layoutStyles';
import NewsArticleSidebar from "../components/newsArticleSidebar"
import PageContent from '../components/pageContent'

export default ({ data, pageContext }) => {
  const post = data.wpgraphql.news
  var newsArticles = [];
  var keywords = [];
  if (post.keywords.nodes != null)
  {
    post.keywords.nodes.forEach(node => { keywords.push(node.name);});
  }
  newsArticles =pageContext.NewsArticles;
  var newsdate = pageContext.NewsDate
  return (
    <Layout>
        <SEO title={post.title} keywords={keywords} />
        <div css={layoutStyles} id="content">
        <div className="grid">
            <div id="sectionPage" className="col-1-1">
              <div id="article" className="col-8-12">
                <h1 dangerouslySetInnerHTML={{ __html: post.title }}></h1>
                <h4>From {post.Newsfields.newsCategory}<br />{newsdate}</h4>
                <PageContent content={post.content} />
              </div>
              <div id="aside" className="col-4-12">
                <NewsArticleSidebar NewsArticles={newsArticles} Category={post.Newsfields.newsCategory} />
              </div>
          </div>
        </div>
        <div className="whitespacer"></div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query GET_NEWS($id: ID!){
      wpgraphql {
        news(id: $id) {
          title
          content
          slug
          keywords {
            nodes {
              name
            }
          }
          Newsfields {
            newsCategory
            newsDate
          }
      }
    }
  }
`