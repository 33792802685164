import React from "react"
import { relatedLinkStyle } from '../style/relatedLinksStyle';
import { Link } from "gatsby"
import PortsLogo from "../images/logo_portsofjersey.png"
import HarboursLogo from "../images/logo_jerseyharbours.png"
import MarinasLogo from "../images/logo_jerseymarinas.png"
import CoastGuardLogo from "../images/logo_jerseycoastguard.png"
import AirportLogo from "../images/logo_jerseyairport.png"



const newsArticleSidebar = (props) => {
  var airportUrl=process.env.GATSBY_AIRPORT_URL;

  return (
    <React.Fragment>
      <div id="headerbanner" className="col-1-1">
      {props.Category === "Jersey Coastguard" && <Link to="/jerseycoastguard" ><img src={CoastGuardLogo} alt="Jersey Coastguard" /></Link> }
      {props.Category === "Port of Jersey" && <Link to="/" ><img src={PortsLogo} alt="Port of Jersey" /></Link> }
      {props.Category === "Jersey Marinas" && <Link to="/jerseymarinas" ><img src={MarinasLogo} alt="Jersey Marinas" /></Link> }
      {props.Category === "Jersey Harbours" && <Link to="/jerseyharbours" ><img src={HarboursLogo} alt="Jersey Harbours" /></Link> }
      {props.Category === "Jersey Airport" && <a href={airportUrl} ><img src={AirportLogo} alt="Jersey Airport" /></a> }
      </div>
      <div css={relatedLinkStyle}  id="related">
        <h2><a href="/news">Latest News</a></h2>
        <ul className="post">
          {props.NewsArticles.map(link => <li key={link.id}>{link.newsCategory!=="Jersey Airport" ? <Link className={link.colorClass} to={link.uri}><strong>{link.dateFormatShort}</strong> -&nbsp;<span dangerouslySetInnerHTML={{ __html: link.title}} /> </Link> : <a className={link.colorClass} href={link.uri}><strong>{link.dateFormatShort}</strong> -&nbsp;<span dangerouslySetInnerHTML={{ __html: link.title}} /></a>}</li>)}
        </ul>
      </div>
    </React.Fragment>
  )
}
export default newsArticleSidebar
